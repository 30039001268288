import React from 'react'
import Layout from 'Components/Layout'
import StatusCodeError from 'Components/StatusCodeError'

const ErrorPage = () => (
  <StatusCodeError statusCode={404} appErrorMessage="Page not found" />
)

export default Layout(ErrorPage, {
  sidebarVariant: 'HOME',
  isFooterPresent: true,
})
