import React from 'react'
import AlertBlock from 'Components/AlertBlock'
import PropTypes from 'prop-types'

function StatusCodeError(props) {
  const { appErrorMessage, statusCode } = props
  // currently status code does nothing

  return (
    <div style={{ padding: '16px' }}>
      <AlertBlock variant="error" statusCode={statusCode}>
        {appErrorMessage}
        <br />
      </AlertBlock>
    </div>
  )
}

StatusCodeError.defaultProps = {
  statusCode: undefined,
  appErrorMessage: undefined,
}

StatusCodeError.propTypes = {
  statusCode: PropTypes.any,
  appErrorMessage: PropTypes.any,
}

export default StatusCodeError
